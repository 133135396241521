
export const appointmentTypes = {
  SELF_APPOINTMENT: 'meus-agendamentos',
  DEPENDANT_APPOINTMENT: 'agendamentos-dependentes'
};

/**
 * Verifica se é um CPF válido
 *
 * @param {string} cpfNumber número do CPF para validação
 * @return {boolean} TRUE para CPF válido e FALSE para cpf inválido
*/
export function cpfValidator(cpfNumber) {
  if(cpfNumber?.length !== 11) return false;

  let sum;

  let rest;

  sum = 0;

  const cpf = cpfNumber.replace(/\D+/g, '');

  if (/^(.)\1+$/.test(cpf)) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
}

/**
 * Verifica se é um número do SUS válido
 *
 * @param {string} susNumber número do SUS para validação
 * @return {boolean} TRUE para número do SUS válido e FALSE para um número inválido
*/
export function susValidator(susNumber) {
  var cns = susNumber.replace(/\./g, '');

  var validaTamanho = cns.length === 15;
  var validaInicio = ['1', '2', '7', '8', '9'].includes(cns[0]);

  if(validaTamanho && validaInicio){
    var soma = 0;

    //CNS Iniciados em 7, 8 ou 9
    if(['7', '8', '9'].includes(cns[0])) {
      soma = cns.split('').reduce((total, value, index) => total + (value * (15 - index)), 0);

      return soma % 11 === 0;
    } else {
      //CNS Iniciados em 1, 2
      var pis = cns.substring(0,11);

      soma = pis.split('').reduce((total, value, index) => total + (value * (15 - index)), 0);
      var resto = soma % 11;
      var dv = resto === 0 ? 0 : 11 - resto;
      var resultado = dv === 10 ? `${pis}001${(11 - ((soma + 2) % 11))}` : `${pis}000${dv}`;

      return resultado === cns;
    }
  }

  return false;
}

/**
 * Formata uma string para o formato de URL
 * @param {string} url O texto da URL
 * @return {string} Retorna a string formatada como uma URL ou como um link vue-router interno
 */
export function formatUrl(url) {
  let link = url;

  // Check if link is valid URL;
  if(!link || !isValidUrl(link)) return '';

  // Check if URL have http or https;
  if(/(http(s?)):\/\//i.test(link)) return link;

  // Add https if don't have;
  return `//${link}`;
}

/**
 * Verifica se uma string é uma URL válida
 * @param {string} urlString O tamanho do arquivo
 * @return {boolean} Se a string está dentro dos padrões de uma URL através do Regex
 */
const isValidUrl = (urlString) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );

  return !!pattern.test(urlString);
};

/**
 * Formata o tamanho de um arquivo em bits
 * @param {number} rawSize O tamanho do arquivo
 * @return {string} Uma única String
 * @example
 *    formatFileSize(123456)
 *    // retorna:
 *    '120.56 kB'
 */
export function formatFileSize(rawSize) {
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];

  let i = 0;

  if(rawSize !== 0) {
    i = Math.floor(Math.log(rawSize) / Math.log(1024));
  }
  const formatedSize = (rawSize / Math.pow(1024, i));

  return `${formatedSize.toFixed(2)} ${units[i]}`;
}

/*
* Settings about cookie to optimizate the API call in home
*/
const bannerCookieName = 'upmed-cookie-banner';
const bannerCookieValue = 'banner-api';
const shortcutCookieName = 'upmed-cookie-shortcut';
const shortcutCookieValue = 'shortcut-api';

// Time to cookie expire: 60 * 60 * 1000 = 1 hour
const expiresCookie = (new Date(Date.now() + 60*60*1000)).toUTCString();

// Get cookies for check if exists
export function getBannerCookie() {
  return document.cookie.match(`(^|;)\\s*${bannerCookieName}\\s*=\\s*([^;]+)`)?.pop() || '';
}

export function getShortcutCookie() {
  return document.cookie.match(`(^|;)\\s*${shortcutCookieName}\\s*=\\s*([^;]+)`)?.pop() || '';
}

// Set cookies in localstorage
export function setBannerCookie() {
  document.cookie = `${bannerCookieName}=${bannerCookieValue}; expires=${expiresCookie}; path=/`;
}

export function setShortcutCookie() {
  document.cookie = `${shortcutCookieName}=${shortcutCookieValue}; expires=${expiresCookie}; path=/`;
}
